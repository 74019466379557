<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import btnComponent from "@/components/btnComponent";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { required } from "vuelidate/lib/validators";
// import branchComponent from "@/components/branchComponent.vue";
// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "เพิ่มข้อมูลโอนรถข้ามสาขา",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      // tableData: tableData,

      title: "เพิ่มข้อมูลโอนรถข้ามสาขา",
      items: [
        {
          text: "คลังสินค้า",
          active: true,
        },
        {
          text: "โอนรถข้ามสาขา",
          active: false,
          href: "/vehicle-transfer",
        },
        {
          text: "เพิ่มข้อมูลโอนรถข้ามสาขา",
          active: true,
        },
      ],
      rowsSelect: [],
      chk: "",
      selected: [],
      rowsTotalpages: "",
      message: "กรุณากรอกข้อมูลให้ครบ",
      rowsperPage: "",
      rowsPage: "",
      submitform: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      arrTest: [],
      loading: false,
      totalRecords: 0,
      pageOptions: [10, 25, 50, 100],
      optionBranch: [],
      rowsStock: [],
      optionStatus: [
        {
          name: "ยืนยันแล้ว",
          status: "APV",
        },
        {
          name: "ยกเลิกแล้ว",
          status: "CAN",
        },
      ],

      fromUserBranchId: "",
      toUserBranchId: "",
      transferDate: "",
      note: "",

      userBranch: "",
      filterOn: [],
      rowsPO: [],
      rowsSuppliers: [],
      sortBy: "age",
      sortDesc: false,
      overlayFlag: false,
    };
  },
  validations: {
    fromUserBranchId: {
      required,
    },
    toUserBranchId: {
      required,
    },
    transferDate: {
      required,
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowsPO.length;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    const arrayBranchAll = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      arrayBranchAll.push(item);
    });
    this.userBranch = arrayBranch;
    this.optionBranch = arrayBranchAll;
    this.totalRows = this.rowsPO.total;
    // this.getDataSuppliers();
  },
  created() {
    // this.getData();
    // this.select()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    transferForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitTransferVehicle();
      }
    },
    submitTransferVehicle() {
      this.overlayFlag = true;
      useNetw
        .post("api/vehicle-transfer/store", {
          branchId:
            this.fromUserBranchId != null ? this.fromUserBranchId.branchId : undefined,
          toBranchId: this.toUserBranchId != null ? this.toUserBranchId.branchId : undefined,
          transferDate: this.transferDate,
          note: this.note,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "detail-vehicle-transfer",
            params: { transferId: btoa(response.data.transferId) },
          });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br/>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Vehicle List</h4> -->
              <div class="row">
                <div class="col-sm-12 col-md-2"></div>
              </div>
              <!-- Search -->
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="row">
                        <div class="col-6 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            <code> * </code>จากสาขา:
                            <multiselect
                              v-model="fromUserBranchId"
                              label="nameTh"
                              type="search"
                              :options="optionBranch"
                              :show-labels="false"
                              open-direction="bottom"
                              placeholder=""
                              :class="{
                                'is-invalid':
                                  submitform && $v.fromUserBranchId.$error,
                              }"
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                            <div
                              v-if="submitform && $v.fromUserBranchId.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.fromUserBranchId.required">{{
                                message
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            <code> * </code>ไปยังสาขา:
                            <multiselect
                              v-model="toUserBranchId"
                              label="nameTh"
                              type="search"
                              :options="optionBranch"
                              :show-labels="false"
                              open-direction="bottom"
                              placeholder=""
                              :class="{
                                'is-invalid':
                                  submitform && $v.toUserBranchId.$error,
                              }"
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                            <div
                              v-if="submitform && $v.toUserBranchId.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.toUserBranchId.required">{{
                                message
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            <code> * </code>วันที่โอนรถ:
                            <date-picker
                              v-model="transferDate"
                              format="YYYY-MM-DD"
                              value-type="format"
                              :class="{
                                'is-invalid':
                                  submitform && $v.transferDate.$error,
                              }"
                            ></date-picker>
                            <div
                              v-if="submitform && $v.transferDate.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.transferDate.required">{{
                                message
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            หมายเหตุ:
                            <b-form-textarea
                              v-model="note"
                              rows="3"
                              max-rows="6"
                            ></b-form-textarea>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 text-end">
                            <b-button
                              class="btn m-2"
                              variant="primary"
                              type="submit"
                              @click="transferForm"
                            >
                              <!-- <i class="uil-plus"></i> -->
                              บันทึก
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
