var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-overlay',{attrs:{"show":_vm.overlayFlag,"spinner-variant":this.$overlayVariant,"spinner-type":this.$overlayType,"rounded":this.$overlayRounded}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-2"})]),_c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col-sm-12 col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-sm-6 col-md-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("จากสาขา: "),_c('multiselect',{class:{
                              'is-invalid':
                                _vm.submitform && _vm.$v.fromUserBranchId.$error,
                            },attrs:{"label":"nameTh","type":"search","options":_vm.optionBranch,"show-labels":false,"open-direction":"bottom","placeholder":""},model:{value:(_vm.fromUserBranchId),callback:function ($$v) {_vm.fromUserBranchId=$$v},expression:"fromUserBranchId"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("ไม่พบข้อมูล")])]),(_vm.submitform && _vm.$v.fromUserBranchId.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.fromUserBranchId.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-6 col-sm-6 col-md-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("ไปยังสาขา: "),_c('multiselect',{class:{
                              'is-invalid':
                                _vm.submitform && _vm.$v.toUserBranchId.$error,
                            },attrs:{"label":"nameTh","type":"search","options":_vm.optionBranch,"show-labels":false,"open-direction":"bottom","placeholder":""},model:{value:(_vm.toUserBranchId),callback:function ($$v) {_vm.toUserBranchId=$$v},expression:"toUserBranchId"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("ไม่พบข้อมูล")])]),(_vm.submitform && _vm.$v.toUserBranchId.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.toUserBranchId.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-6 col-sm-6 col-md-2"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("วันที่โอนรถ: "),_c('date-picker',{class:{
                              'is-invalid':
                                _vm.submitform && _vm.$v.transferDate.$error,
                            },attrs:{"format":"YYYY-MM-DD","value-type":"format"},model:{value:(_vm.transferDate),callback:function ($$v) {_vm.transferDate=$$v},expression:"transferDate"}}),(_vm.submitform && _vm.$v.transferDate.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.transferDate.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-6 col-sm-6 col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" หมายเหตุ: "),_c('b-form-textarea',{attrs:{"rows":"3","max-rows":"6"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12 text-end"},[_c('b-button',{staticClass:"btn m-2",attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.transferForm}},[_vm._v(" บันทึก ")])],1)])])])])])])])])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }